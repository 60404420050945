<template>
    <v-container grid-list-xl fluid>
        <h1>Inventário</h1>
        <v-divider class="my-4" />
        <v-layout wrap>
            <v-flex xs12>
                <h3>Status</h3>
                <v-divider />
            </v-flex>

            <v-flex xs12 sm3 v-for="(value, key, index) in packagesStatus" :key="index">
                <v-btn @click="status = key" :outlined="status != key" :color="status == key ? 'warning' : 'grey'" width="100%" small>
                    {{ value }}
                </v-btn>
            </v-flex>

            <v-flex xs12>
                <v-divider />
            </v-flex>

            <v-flex xs12>
                <v-text-field :label="$t('Código de barras')" v-model="ticket" clearable outlined hide-details v-on:keyup.enter="bip()" append-icon="qr_code_scanner"
                    append-outer-icon="mdi-send" @click:append="openReadQRCode" @click:append-outer="bip()" :disabled="status == null" />
            </v-flex>
            <v-flex xs12>
                <v-btn @click="bip()" color="warning" width="100%" :disabled="status == null">
                    <v-icon left>published_with_changes</v-icon>Alterar status
                </v-btn>
            </v-flex>
            <v-flex xs12 v-if="items && items.length > 0">
                <h3>Histórico de ações</h3>
                <v-divider />
                <v-list v-for="(item, index) in listToShow" :key="index" width="100vw">
                    <v-list-item>
                        <v-list-item-content>
                            <v-list-item-title v-if="item.ticket">Pedido: {{ item.ticket }}</v-list-item-title>
                            <v-list-item-subtitle v-if="item.status">Status: {{ packagesStatus[item.status] }}</v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                    <v-divider :key="index" />
                </v-list>
            </v-flex>
        </v-layout>

        <div class="ma-4 text-center" v-if="loaded == false">
            <v-progress-circular size="140" width="20" color="primary" indeterminate />
        </div>
        <ConfirmModal confirmText="Confirmar" cancelText="Cancelar" ref="confirm" />
        <BarCode :show="showQRCode" @close="showQRCode = false" @onRead="readedQRCode" />
    </v-container>
</template>

<script>
import ConfirmModal from "@/components/core/ConfirmModal";
import BarCode from "@/components/core/BarCode";
import packagesStatus from "@/assets/json/packagesStatus.json";

export default {
    components: { ConfirmModal, BarCode },

    data() {
        return {
            ticket: null,
            status: null,
            showQRCode: false,
            items: [],
            loaded: true,
            packagesStatus
        };
    },

    computed: {
        listToShow() {
            return this.items ? [...this.items].reverse() : null;
        }
    },

    methods: {
        openReadQRCode() {
            this.showQRCode = true;
        },
        readedQRCode(QRCode) {
            this.ticket = QRCode;
            this.bip();
        },
        async bip(forced = false) {
            if (this.status) {
                // this.showQRCode = false;
                this.loaded = false;

                await this.$http
                    .post(`packages/status`, { status: this.status, ticket: this.ticket, forced: (forced ? true : false) })
                    .then((result) => {
                        if (result) {
                            if (result.status != undefined) {
                                if (result.needConfimation == true) {
                                    this.$refs.confirm.open("",
                                        (result.status != this.status) ?
                                            `Pacote já possui o status de "${this.packagesStatus[result.status]}", deseja realmente alterar?` :
                                            `Pedido "${this.ticket}" já está com status "${this.packagesStatus[result.status]}", deseja forçar uma atualização?`
                                    ).then((confirm) => {
                                        if (confirm) {
                                            this.bip(true);
                                        }
                                    });
                                } else {
                                    this.items.push({ ticket: this.ticket, status: this.status });
                                    this.ticket = null;
                                }
                            }
                        } else {
                            this.$eventHub.$emit("msgError", "Pedido não localizado");
                        }
                    })
                    .catch((e) => {
                        this.$eventHub.$emit("msgError", e.message ? e.message : "Erro ao buscar.");
                    })
                    .finally(() => this.loaded = true);
            }
        },
    },
};
</script>